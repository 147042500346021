@font-face {
    font-family: "Azo Sans";
    src: url("AzoSans-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Azo Sans";
    src: url("AzoSans-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Azo Sans Italic";
    src: url("AzoSans-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Azo Sans Italic";
    src: url("AzoSans-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Azo Sans";
    src: url("AzoSans-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Azo Sans Italic";
    src: url("AzoSans-BlackItalic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Azo Sans";
    src: url("AzoSans-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Azo Sans";
    src: url("AzoSans-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Azo Sans Italic";
    src: url("AzoSans-BoldItalic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Azo Sans Italic";
    src: url("AzoSans-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Azo Sans";
    src: url("AzoSans-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Azo Sans Italic";
    src: url("AzoSans-ThinItalic.ttf") format("truetype");
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}
