.form-control {
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: none;
  margin-bottom: 15px;
  min-width: 200px;
}
.form-control:hover,
.form-control:focus,
.form-control:active {
  box-shadow: none;
  background-color: #f3f3f5;
}
.form-control:focus {
  border: 1px solid #34495e;
}
.select2.select2-container {
  width: 100% !important;
}
.select2.select2-container .select2-selection {
  border: 1px solid #ccc;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  height: 34px;
  margin-bottom: 15px;
  outline: none;
  transition: all 0.15s ease-in-out;

  font-size: 14px !important;
  color: #0d3b5d;
  /* border: 1px solid rgba(142, 152, 160, 0.6) !important; */
  border-radius: 4px !important;
  min-height: 40px !important;
  padding: 8px 15px 8px 15px !important;
  line-height: 24px !important;
  margin: 0 0 5px 0 !important;
  font-weight: 500 !important;
}
.select2.select2-container .select2-selection .select2-selection__rendered {
  color: #3c454c;
  line-height: 22px !important;
  padding-right: 33px;
  padding-left: 0 !important;
}


.select-custom2
  .select2.select2-container
  .select2-selection
  .select2-selection__arrow {
  height: 20px !important;
}

.select2-remove {
  color: red;

}

.select2-remove > .fe {
  cursor: pointer;
}

.select2-container .select2-dropdown .select2-results ul {
  border: 0 !important;
  outline: 0 !important;
  box-shadow: none !important;
  margin: 0 !important;
  padding: 0 !important;
}
.select2.select2-container .select2-selection .select2-selection__arrow {
  background: #f8f8f8;
  border-left: 1px solid #ccc;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  height: 32px;
  width: 33px;
}
.select2.select2-container.select2-container--open
  .select2-selection.select2-selection--single {
  background: #f8f8f8;
}
.select2.select2-container.select2-container--open
  .select2-selection.select2-selection--single
  .select2-selection__arrow {
  -webkit-border-radius: 0 3px 0 0;
  -moz-border-radius: 0 3px 0 0;
  border-radius: 0 3px 0 0;
}
.select2.select2-container.select2-container--open
  .select2-selection.select2-selection--multiple {
  border: 1px solid #34495e;
}
.select2.select2-container.select2-container--focus .select2-selection {
  border: 1px solid #34495e;
}
.select2.select2-container .select2-selection--multiple {
  height: auto;
  min-height: 34px;
}
.select2.select2-container
  .select2-selection--multiple
  .select2-search--inline
  .select2-search__field {
  margin-top: 0;
  height: 32px;
}
.select2.select2-container
  .select2-selection--multiple
  .select2-selection__rendered {
  display: block;
  padding: 0 4px;
  line-height: 29px;
}
.select2.select2-container
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin: 4px 4px 0 0;
  padding: 0 6px 0 22px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  position: relative;
}
.select2.select2-container
  .select2-selection--multiple
  .select2-selection__choice
  .select2-selection__choice__remove {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  margin: 0;
  text-align: center;
  color: #e74c3c;
  font-weight: 700;
  font-size: 16px;
}
.select2-container .select2-dropdown {
  background: transparent;
  border: none;
  margin-top: -5px;
}
.select2-container .select2-dropdown .select2-search {
  padding: 0;
}
.select2-container .select2-dropdown .select2-search input {
  outline: none;
  border: 1px solid #34495e;
  border-bottom: none;
  padding: 4px 6px;
}
.select2-container .select2-dropdown .select2-results {
  padding: 0;
}
.select2-container .select2-dropdown .select2-results ul {
  background: #fff;
  border: 1px solid #34495e;
}
.select2-container
  .select2-dropdown
  .select2-results
  ul
  .select2-results__option--highlighted[aria-selected] {
  background-color: #3498db;
}

.group-select2-div
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered
  li {
  position: relative;
  padding: 0 5px 0 20px !important;
}

.group-select2-div
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove {
  padding: 1px 0 0 0 !important;
}
