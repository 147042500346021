.coaches-summary .coaches-report-inner .summary-form.summary-form2 {
  padding: 40px 40px;
}

#fm-pivot-view .fm-ui,
#fm-pivot-view .fm-ui a,
#fm-pivot-view .fm-ui div,
#fm-pivot-view .fm-ui input,
#fm-pivot-view .fm-ui li,
#fm-pivot-view .fm-ui p,
#fm-pivot-view .fm-ui select,
#fm-pivot-view .fm-ui span,
#fm-pivot-view .fm-ui table,
#fm-pivot-view .fm-ui table td,
#fm-pivot-view .fm-ui table th,
#fm-pivot-view .fm-ui table tr,
#fm-pivot-view .fm-ui textarea,
#fm-pivot-view .fm-ui ul,
#fm-toolbar-wrapper .fm-toolbar-ui,
#fm-toolbar-wrapper .fm-toolbar-ui a,
#fm-toolbar-wrapper .fm-toolbar-ui div,
#fm-toolbar-wrapper .fm-toolbar-ui input,
#fm-toolbar-wrapper .fm-toolbar-ui li,
#fm-toolbar-wrapper .fm-toolbar-ui p,
#fm-toolbar-wrapper .fm-toolbar-ui select,
#fm-toolbar-wrapper .fm-toolbar-ui span,
#fm-toolbar-wrapper .fm-toolbar-ui table,
#fm-toolbar-wrapper .fm-toolbar-ui table td,
#fm-toolbar-wrapper .fm-toolbar-ui table th,
#fm-toolbar-wrapper .fm-toolbar-ui table tr,
#fm-toolbar-wrapper .fm-toolbar-ui textarea,
#fm-toolbar-wrapper .fm-toolbar-ui ul {
  font-family: "Azo Sans" !important;
}

.fm-ui-element .fm-header {
  background-color: #555 !important;
  color: #fff !important;
}

.fm-icon .fm-filter-icon {
  color: #fff !important;
  background-color: #fff !important;
}

#fm-pivot-view .fm-grid-layout i.fm-icon:before {
  color: #fff !important;
}

#fm-pivot-view .fm-fields-view.fm-pivot-fields {
  max-width: 1200px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0 0 20px 0 #00000033;
  height: 500px;
  min-height: 500px;
}

#fm-pivot-view
  .fm-fields-view
  .fm-popup-content
  .fm-list-wrap
  .fm-list-header.fm-list-header-wide {
  height: 40px;
  background-color: #ebeded;
  color: #071c2e;
}

#fm-pivot-view {
  border: 1px solid #e9e9e9;
}

#fm-toolbar-wrapper #fm-toolbar li {
  margin: 0 10px;
}

#fm-toolbar-wrapper #fm-toolbar .fm-toolbar-group-right {
  padding: 0 10px 0 0;
}

#fm-pivot-view .fm-ui .fm-ui-col-3,
#fm-toolbar-wrapper .fm-ui .fm-ui-col-3 {
  margin-right: 15px;
  width: calc(33.33% - 10px);
}

#fm-pivot-view
  .fm-grid-layout
  div.fm-scroll-pane
  div.fm-scroll-content
  .fm-cell {
  font-size: 13px;
  padding: 7px 15px;
}

#fm-pivot-view .fm-grid-layout .fm-filters.fm-rows-filter .fm-filter-header {
  font-size: 14px;
}

#fm-pivot-view .fm-grid-layout .fm-filters.fm-rows-filter .fm-filter-header {
  padding-left: 15px;
}

#fm-pivot-view div.fm-ui-window .fm-popup-header {
  margin-bottom: 20px;
}

#fm-pivot-view button.fm-ui-btn {
  color: #071c2e;
  border: 1px solid rgba(7, 28, 46, 0.3);
}

#fm-pivot-view button.fm-ui-btn-dark,
#fm-toolbar-wrapper button.fm-ui-btn-dark {
  color: #fff;
  background: #3383ff;
  border: 1px solid #3383ff;
  transition: all 0.8s;
}

#fm-pivot-view button.fm-ui-btn-dark:hover,
#fm-toolbar-wrapper button.fm-ui-btn-dark:hover {
  color: #fff;
  background: #3383ff;
  border: 1px solid #3383ff;
}

#fm-pivot-view a.fm-ui-btn-dark:hover,
#fm-pivot-view button.fm-ui-btn-dark:hover,
#fm-toolbar-wrapper a.fm-ui-btn-dark:hover,
#fm-toolbar-wrapper button.fm-ui-btn-dark:hover {
  background: #3383ff;
  border-color: #0083ff;
  box-shadow: 0 5px 15px 0 #42b3e766;
}

#fm-pivot-view
  .fm-fields-view
  .fm-popup-content
  .fm-list-wrap
  .fm-list-header
  span.fm-ui-label {
  font-size: 12px;
  line-height: 20px;
  font-weight: 800;
  color: #071c2e;
  display: inline-block;
}

#fm-pivot-view .fm-fields-view .fm-popup-content .fm-list-wrap .fm-list-header {
  min-height: 40px;
  background-color: #ebeded;
  color: #071c2e;
  padding: 10px 12px 7px 12px;
}

.fm-charts-color-1 {
  fill: #ee3452 !important;
}

.fm-charts-color-2 {
  fill: #012d68 !important;
}

.fm-charts-color-3 {
  fill: #fece18 !important;
}

.fm-charts-color-4 {
  fill: #f05ab4 !important;
}

.fm-charts-color-5 {
  fill: #168bdf !important;
}

.fm-charts-color-6 {
  fill: #0083ff !important;
}

.fm-charts-color-7 {
  fill: #00b385 !important;
}

.fm-charts-color-8 {
  fill: #1b5aa3 !important;
}

@media (max-width: 767px) {
  #fm-pivot-view
    div.fm-ui-popup.fm-layout-mobile-small
    .fm-popup-header
    .fm-ui-btns-row
    .fm-ui-btn {
    width: auto;
  }

  #fm-pivot-view div.fm-ui-popup.fm-layout-mobile {
    padding: 15px;
    overflow-y: scroll;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  #fm-pivot-view .fm-fields-view.fm-layout-mobile .fm-ui-col-3 {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  #fm-pivot-view .fm-fields-view.fm-layout-mobile-small .fm-popup-content {
    height: 100%;
    max-height: 300px;
  }
}
