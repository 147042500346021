@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon/icomoon.eot");
  src: url("../fonts/icomoon/icomoon.eot") format("embedded-opentype"),
    url("../fonts/icomoon/icomoon.ttf") format("truetype"),
    url("../fonts/icomoon/icomoon.woff") format("woff"),
    url("../fonts/icomoon/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon";
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Toggle---On .path1::before {
  content: "\e900";
  color: rgb(66, 180, 231);
}

.icon-Toggle---On .path2::before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(248, 244, 244);
}

.icon-Toggle---Off .path1::before {
  content: "\e902";
  color: rgb(142, 152, 160);
}

.icon-Toggle---Off .path2::before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(248, 244, 244);
}

.icon-More::before {
  content: "\e904";
}

.icon-Notifications .path1::before {
  content: "\e905";
  color: rgb(255, 255, 255);
}

.icon-Notifications .path2::before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(7, 28, 46);
}

.icon-Notifications .path3::before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(7, 28, 46);
}

.icon-DateModify::before {
  content: "\e908";
}

.icon-CreatedBy::before {
  content: "\e909";
}

.icon-Forms .path1::before {
  content: "\e90a";
  color: rgb(255, 255, 255);
}

.icon-Forms .path2::before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(7, 28, 46);
}

.icon-Forms .path3::before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(7, 28, 46);
}

.icon-Fields::before {
  content: "\e90d";
}

.icon-Formats::before {
  content: "\e90e";
}

.icon-Calculator .path1::before {
  content: "\e90f";
  color: rgb(142, 152, 160);
}

.icon-Calculator .path2::before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(142, 152, 160);
  opacity: 0.3046;
}

.icon-Calculator .path3::before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(142, 152, 160);
  opacity: 0.3046;
}

.icon-Calculator .path4::before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(142, 152, 160);
}

.icon-Calculator .path5::before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-Calculator .path6::before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-Calculator .path7::before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-Calculator .path8::before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-Calculator .path9::before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-Calculator .path10::before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-Calculator .path11::before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-LeftArrowOne::before {
  content: "\e91a";
}

.icon-LeftArrowTwo::before {
  content: "\e91b";
}

.icon-RightArrowone::before {
  content: "\e91c";
}

.icon-RightArrowTwo::before {
  content: "\e91d";
}

.icon-Reports .path1::before {
  content: "\e91e";
  color: rgb(255, 255, 255);
}

.icon-Reports .path2::before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(7, 28, 46);
}

.icon-Reports .path3::before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(7, 28, 46);
}

.icon-TableSettings .path1::before {
  content: "\e921";
  color: rgb(255, 255, 255);
}

.icon-TableSettings .path2::before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(7, 28, 46);
}

.icon-LeftArrowFirst::before {
  content: "\e923";
  color: #8e98a0;
}

.icon-RightArrowLast::before {
  content: "\e924";
  color: #42b4e7;
}

.icon-Filter::before {
  content: "\e925";
  color: #8e98a0;
}

.icon-Sort::before {
  content: "\e926";
}

.icon-Delete2::before {
  content: "\e927";
  color: #f45c56;
}

.icon-ViewTable .path1::before {
  content: "\e928";
  color: rgb(255, 255, 255);
}

.icon-ViewTable .path2::before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(7, 28, 46);
}

.icon-ViewTable .path3::before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(7, 28, 46);
}

.icon-Refresh::before {
  content: "\e92b";
}

.icon-Schedule::before {
  content: "\e92c";
}

.icon-LeftArrowsmall::before {
  content: "\e92d";
  color: #8e98a0;
}

.icon-RightArrowSmall::before {
  content: "\e92e";
  color: #8e98a0;
}

.icon-Checkbox---Selected .path1::before {
  content: "\e92f";
  color: rgb(48, 48, 48);
}

.icon-Checkbox---Selected .path2::before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(250, 250, 250);
}

.icon-Checkbox::before {
  content: "\e931";
}

.icon-Imports .path1::before {
  content: "\e932";
  color: rgb(255, 255, 255);
}

.icon-Imports .path2::before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(7, 28, 46);
}

.icon-ERD::before {
  content: "\e934";
  color: #979797;
}

.icon-RadioButton---Selected::before {
  content: "\e935";
  color: #575757;
}

.icon-RadioButton::before {
  content: "\e936";
  color: #575757;
}

.icon-Relationships .path1::before {
  content: "\e937";
  color: rgb(255, 255, 255);
}

.icon-Relationships .path2::before {
  content: "\e938";
  margin-left: -1em;
  color: rgb(7, 28, 46);
}

.icon-Relationships .path3::before {
  content: "\e939";
  margin-left: -1em;
  color: rgb(7, 28, 46);
}

.icon-Relationships .path4::before {
  content: "\e93a";
  margin-left: -1em;
  color: rgb(7, 28, 46);
}

.icon-Relationships .path5::before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(7, 28, 46);
}

.icon-Relationships .path6::before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(7, 28, 46);
}

.icon-Relationships .path7::before {
  content: "\e93d";
  margin-left: -1em;
  color: rgb(7, 28, 46);
}

.icon-Relationships .path8::before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(7, 28, 46);
}

.icon-Relationships .path9::before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(7, 28, 46);
}

.icon-PrimaryKey::before {
  content: "\e940";
  color: #ff9400;
}

.icon-Delete::before {
  content: "\e941";
}

.icon-TableSettings2::before {
  content: "\e942";
}

.icon-Notification2::before {
  content: "\e943";
}

.icon-Forms2::before {
  content: "\e944";
}

.icon-Reports2::before {
  content: "\e945";
}

.icon-Field2::before {
  content: "\e946";
}

.icon-Relationships2::before {
  content: "\e947";
}

.icon-Import2::before {
  content: "\e948";
}

.icon-Edit2::before {
  content: "\e949";
}

.icon-Visible2::before {
  content: "\e94a";
}

.icon-LeftArrowOval1 .path1::before {
  content: "\e94b";
  color: rgb(66, 180, 231);
}

.icon-LeftArrowOval1 .path2::before {
  content: "\e94c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-Setting::before {
  content: "\e94d";
}

.icon-RightArrow::before {
  content: "\e94e";
  color: #fff;
}

.icon-Folder::before {
  content: "\e94f";
  color: #838d96;
}

.icon-Folder---Selected::before {
  content: "\e950";
  color: #fff;
}

.icon-Upload::before {
  content: "\e951";
  color: #8e98a0;
}

.icon-DefaultIcon---Selected .path1::before {
  content: "\e952";
  color: rgb(66, 180, 231);
  opacity: 0.0978;
}

.icon-DefaultIcon---Selected .path2::before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-DefaultIcon---Selected .path3::before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(66, 180, 231);
}

.icon-DefaultIcon---Selected .path4::before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-DefaultIcon---Selected .path5::before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(66, 180, 231);
}

.icon-DefaultIcon---Selected .path6::before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-DefaultIcon6 .path1::before {
  content: "\e958";
  color: rgb(66, 180, 231);
  opacity: 0.0978;
}

.icon-DefaultIcon6 .path2::before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-DefaultIcon5 .path1::before {
  content: "\e95a";
  color: rgb(66, 180, 231);
  opacity: 0.0978;
}

.icon-DefaultIcon5 .path2::before {
  content: "\e95b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-DefaultIcon4 .path1::before {
  content: "\e95c";
  color: rgb(66, 180, 231);
  opacity: 0.0978;
}

.icon-DefaultIcon4 .path2::before {
  content: "\e95d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-DefaultIcon7 .path1::before {
  content: "\e95e";
  color: rgb(66, 180, 231);
  opacity: 0.0978;
}

.icon-DefaultIcon7 .path2::before {
  content: "\e95f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-DefaultIcon8 .path1::before {
  content: "\e960";
  color: rgb(66, 180, 231);
  opacity: 0.0978;
}

.icon-DefaultIcon8 .path2::before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-DefaultIcon2 .path1::before {
  content: "\e962";
  color: rgb(66, 180, 231);
  opacity: 0.0978;
}

.icon-DefaultIcon2 .path2::before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-DefaultIcon1 .path1::before {
  content: "\e964";
  color: rgb(66, 180, 231);
  opacity: 0.0978;
}

.icon-DefaultIcon1 .path2::before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-DefaultIcon3 .path1::before {
  content: "\e966";
  color: rgb(66, 180, 231);
  opacity: 0.0978;
}

.icon-DefaultIcon3 .path2::before {
  content: "\e967";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-DownArrow::before {
  content: "\e968";
  color: #8e98a0;
}

.icon-Close::before {
  content: "\e969";
}

.icon-EditImage::before {
  content: "\e96a";
  color: #fff;
}

.icon-VisibleOff::before {
  content: "\e96b";
}

.icon-Logout::before {
  content: "\e96c";
}

.icon-Username2::before {
  content: "\e96d";
}

.icon-Search .path1::before {
  content: "\e96e";
  color: rgb(250, 250, 250);
}

.icon-Search .path2::before {
  content: "\e96f";
  margin-left: -1em;
  color: rgb(8, 28, 46);
  opacity: 0.8756;
}

.icon-Plus::before {
  content: "\e970";
}

.icon-LeftArrowOval .path1::before {
  content: "\e971";
  color: rgb(242, 242, 242);
}

.icon-LeftArrowOval .path2::before {
  content: "\e972";
  margin-left: -1em;
  color: rgb(8, 28, 46);
}

.icon-RightArrow-small .path1::before {
  content: "\e973";
  color: rgb(0, 0, 0);
}

.icon-RightArrow-small .path2::before {
  content: "\e974";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-RightArrow-small .path3::before {
  content: "\e975";
  margin-left: -1em;
  color: rgb(142, 152, 160);
}

.icon-Dashboard::before {
  content: "\e976";
  color: #838d96;
}

.icon-Visible::before {
  content: "\e977";
  color: #8e98a0;
}

.icon-Password::before {
  content: "\e978";
  color: #8e98a0;
}

.icon-UserName1::before {
  content: "\e979";
  color: #8e98a0;
}
